import React from 'react';
import {Box, Container, Typography, Stack, useMediaQuery} from '@mui/material';
import Background from '/public/images/about-us.png';

const TopBlock = () => {
    const isMd = useMediaQuery(theme => theme.breakpoints.up('sm'));
    return (
        <Stack
            sx={{
                width: '100%',
                height: [250, 640],
                justifyContent: 'center',
                background: `linear-gradient(328.05deg, rgba(243, 30, 40, 0) 24.12%, #D12E28 76.7%), url(${Background.src})   center/cover no-repeat  `
            }}>
            <Container>
                <Box
                    sx={{
                        color: 'secondary.main'
                    }}>
                    <Typography variant={'t1'}>О компании</Typography>
                    <Typography variant={'h3'} sx={{color: 'inherit', fontWeight: 400, mt: 2}}>
                        Расширяя горизонты {!isMd && <br />} доставки
                    </Typography>
                </Box>
            </Container>
        </Stack>
    );
};

export default TopBlock;
