import React from 'react';
import {Typography, Grid, Container, CardMedia, Box, Stack, useMediaQuery} from '@mui/material';
import Dir from '/public/images/dir.png';
import DirMobile from '/public/images/dir-mobile.png';

const DirectorAppeal = () => {
    const isDesktop = useMediaQuery('(min-width:1000px)');
    const APPEAL = [
        'Есть одно незыблемое правило: чтобы изменить мир вокруг себя, нужно самому начать меняться. И мы сделали это – создали собственную транспортную компанию, которая не позволяет себе подвести заказчика. Это наш принцип, наше понимание рабочей задачи.  ',
        ' Профессионализм и ответственность – залог нашего, а значит и ВАШЕГО успеха. LDC всегда к вашим услугам!'
    ];
    const SIGNATURE = ['С Уважением,', 'Макаренко Ксения\nДиректор по развитию Logistics Delivery Cargo'];

    return (
        <>
            <Container
                sx={{
                    textAlign: ['left', 'center'],
                    mt: 8.5,
                    pb: [43, 43],
                    background: {
                        xs: `url(${DirMobile.src})   left bottom -100px no-repeat  `,
                        sm: `url(${Dir.src})   center bottom -120px no-repeat  `
                    }
                }}>
                <Typography variant={isDesktop ? 'h1' : 'h2'} sx={{mb: 6}}>
                    Обращение директора компании
                </Typography>
                <Box
                    sx={{
                        columnGap: 8,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: ['flex-start', 'center'],
                        flexDirection: ['column', 'row'],
                        my: 7
                    }}>
                    <Typography variant={'h2'}>«</Typography>
                    <Stack sx={{alignItems: 'center', my: 1, rowGap: 3}}>
                        {APPEAL.map((item, index) => (
                            <Box key={index} sx={{maxWidth: 570}}>
                                <Typography variant={'body1'}>{item}</Typography>
                            </Box>
                        ))}
                    </Stack>
                    <Typography variant={'h2'}>»</Typography>
                </Box>
                <Stack sx={{alignItems: 'center', rowGap: 2}}>
                    {SIGNATURE.map((item, index) => (
                        <Box key={index} sx={{maxWidth: 570, color: 'grey.description'}}>
                            <Typography variant={'body2'} sx={{whiteSpace: 'pre-wrap'}}>
                                {item}
                            </Typography>
                        </Box>
                    ))}
                </Stack>
            </Container>
        </>
    );
};

export default DirectorAppeal;
