import React from 'react';
import {Box, Container, Typography, Stack, useMediaQuery, Grid, SvgIcon} from '@mui/material';
import Award from '/public/icons/award.svg';
import Heart from '/public/icons/heart.svg';
import Navigation from '/public/icons/navigation.svg';
import Phone from '/public/icons/phone-call.svg';
import LineF from '/public/images/linefone.png';
import MLineF from '/public/images/mlinefone.png';

const HowWeWork = () => {
    const isDesktop = useMediaQuery('(min-width:1000px)');
    const RIGHT = [
        {
            title: 'Ориентир на\nпрофессионализм',
            description:
                'Благодаря разработке и внедрению собственных IT-решений в транспортно-логистической отрасли, компания успешно решает поставленные задачи.',
            icon: Award
        },
        {
            title: 'Уделяем особое\nвнимание',
            description:
                'Высокому уровню сервиса, гибкой ценовой политике, обеспечению быстрой и безопасной доставки груза. ',
            icon: Heart
        },
        {
            title: 'Простая\nи понятная цель',
            description:
                'Бережно доставлять груз в кратчайшие сроки. Мы обеспечим наиболее эффективное решение поставленной задачи, комфортные условия сделки и сохранность доверенного нам груза.',
            icon: Navigation
        },
        {
            title: 'Круглосуточная\nподдержка 24/7',
            description:
                'Наша компания осуществляет поддержку в режиме 24/7, выведя процесс коммуникаций на принципиально новый уровень: уточнить любую интересующую информацию можно у оператора, в социальных сетях, а также на нашем сайте.',
            icon: Phone
        }
    ];
    return (
        <Stack
            sx={{
                width: '100%',
                justifyContent: 'center',
                pt: [3, 3],
                background: {
                    md: ` url(${LineF.src}) center bottom -30px no-repeat `,
                    xs: ` url(${MLineF.src})  left bottom/cover no-repeat `
                }
            }}>
            <Container>
                <Grid container>
                    <Grid item sx={{minWidth: 312, mr: 21}}>
                        <Typography variant={isDesktop ? 'h1' : 'h2'}>Как мы работаем?</Typography>
                        <Typography sx={{color: 'grey.description', mt: 1.25, mb: 6}} variant={'body1'}>
                            Мы совершенствуемся для вас!
                        </Typography>
                    </Grid>

                    <Grid container sx={{pb: [0, 10]}} item xs={7} gap={5}>
                        {RIGHT.map((item, index) => (
                            <Grid item key={index} sx={{maxWidth: 316}}>
                                <Box
                                    sx={{
                                        background: theme => theme.palette.primary.main,
                                        borderRadius: '50%',
                                        height: 50,
                                        width: 50,
                                        mb: 2.5,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                    <SvgIcon
                                        inheritViewBox
                                        component={item.icon}
                                        sx={{
                                            fill: theme => theme.palette.primary.main
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <Typography variant={'h4'} sx={{pb: 0.5, whiteSpace: 'pre-wrap'}}>
                                        {item.title}
                                    </Typography>
                                    <Typography variant={'body2'} sx={{color: 'grey.description'}}>
                                        {item.description}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Container>
        </Stack>
    );
};

export default HowWeWork;
