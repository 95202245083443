import React from 'react';
import {Typography, Grid, Container} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

const History = () => {
    const isMd = useMediaQuery(theme => theme.breakpoints.up('md'));
    const isDesktop = useMediaQuery('(min-width:1000px)');
    const LEFT = [
        {
            title: '2017',
            description: 'Год создания'
        },
        {
            title: '> 1 000',
            description: 'Транспортных\nсредств'
        },
        {
            title: '2 000',
            description: 'Отделений\nпо всей России'
        },
        {
            title: '88 000',
            description: 'Населенных\nпунктов'
        }
    ];
    const RIGHT = [
        'Компания LDC основана в 2017 году. Ведущим направлением её деятельности является перевозка сборных грузов и коммерческой почты. LDC предоставляет удобные логистические решения как для бизнеса, торговых сетей и интернет-магазинов, так и для частных клиентов. Наше непрерывное стремление к совершенству помогает клиентам LDC преодолевать пространство и расширять горизонты. ',

        'На сегодняшний день география выполняемых нами заказов охватывает не только все регионы России от Калининграда до Владивостока, но и страны СНГ. Команда LDC эффективно и бережно доставляет грузы в более чем 2000 отделений, 88000 населенных пунктов. А существующий на данный момент автопарк является одним из самых молодых среди представителей отрасли в стране: на балансе компании находится свыше 1000 транспортных средств, средний возраст которых не превышает два года.'
    ];
    return (
        <>
            <Container>
                <Typography variant={isDesktop ? 'h1' : 'h2'} sx={{my: [4.5, 8]}}>
                    Немного истории
                </Typography>
                <Grid container columnSpacing={12}>
                    <Grid container sx={{minWidth: 326}} item xs={12} sm={4}>
                        {LEFT.map((item, index) => (
                            <Grid item xs={6} sx={{mb: 4}} key={index}>
                                <Typography variant={'h4'} sx={{pb: 1.25, color: 'primary.main'}}>
                                    {item.title}
                                </Typography>
                                <Typography variant={'body2'} sx={{color: 'grey.description', whiteSpace: 'pre-wrap'}}>
                                    {item.description}
                                </Typography>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        {RIGHT.map((item, index) => (
                            <Typography key={index} variant={'body1'} sx={{color: 'grey.description', mb: 1.25}}>
                                {item}
                            </Typography>
                        ))}
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default History;
