import React from 'react';
import {Typography, Grid, Container, CardMedia, useMediaQuery} from '@mui/material';
import Invitro from '/public/images/invitro.png';
import KDL from '/public/images/kdl.png';
import Gemotest from '/public/images/gemotest.png';

const WhoWorksWithUs = () => {
    const isDesktop = useMediaQuery('(min-width:1000px)');
    const COMPANY = [
        {
            title: 'Invitro',
            icon: Invitro
        },
        {
            title: 'KDL',
            icon: KDL
        },
        {
            title: 'Гемотест',
            icon: Gemotest
        }
    ];

    return (
        <>
            <Container sx={{textAlign: ['left', 'center'], mt: [9, 10], mb: [3, 10]}}>
                <Typography variant={isDesktop ? 'h1' : 'h2'} sx={{mb: 8}}>
                    С нами работают
                </Typography>
                <Grid container spacing={7} sx={{justifyContent: ['left', 'center']}}>
                    {COMPANY.map((item, index) => (
                        <Grid
                            item
                            sx={{
                                display: 'flex',
                                alignItems: 'flex-end',
                                '&:nth-child(2)': {order: [2, 0]}
                            }}
                            key={index}>
                            <CardMedia component="img" image={item.icon.src} alt={item.title} sx={{width: 'auto'}} />
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </>
    );
};

export default WhoWorksWithUs;
