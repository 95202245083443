import React from 'react';
import LandingLayout from 'layouts/LandingLayout';
import TopBlock from 'views/aboutUs/components/TopBlock';
import History from 'views/aboutUs/components/History';
import WhyChooseUs from 'views/aboutUs/components/WhyChooseUs';
import HowWeWork from 'views/aboutUs/components/HowWeWork';
import WhoWorksWithUs from 'views/aboutUs/components/WhoWorksWithUs';
import DirectorAppeal from 'views/aboutUs/components/DirectorAppeal';
import {withApollo} from 'config/apolloConfig';

const Index = () => {
    return (
        <LandingLayout>
            <TopBlock />
            <History />
            <WhyChooseUs />
            <HowWeWork />
            <WhoWorksWithUs />
            <DirectorAppeal />
        </LandingLayout>
    );
};

export default withApollo({ssr: true})(Index);

// import * as React from 'react';
// import LandingLayout from 'layouts/LandingLayout';
// import Landing from 'views/landing/Landing';
// import {withApollo} from 'config/apolloConfig';

// const Index = () => {
//     return (
//         <LandingLayout>
//             <Landing />
//         </LandingLayout>
//     );
// };

// export default withApollo({ssr: true})(Index);
