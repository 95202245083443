import React from 'react';
import {Container, Typography, Stack, useMediaQuery, Grid} from '@mui/material';
import Triangles from '/public/images/triangles.png';

const WhyChooseUs = () => {
    const isMd = useMediaQuery('(min-width:1700px)');
    const isDesktop = useMediaQuery('(min-width:1000px)');
    const ANSWER = [
        ' Потому что мы – передовая логистическая компания, использующая синергию в бизнесе.  А это значит, что собранные воедино компоненты бизнес-процесса – от профессионализма  и ответственности команды до передовых технологий и современного автопарка – помогают нам соответствовать выбранной миссии: обеспечению максимально быстрой и качественной доставки грузов. Как показывает практика, нам это удается!',
        'Сегодня LDC доверяют крупные компании, от эффективности транспортной логистики которых зависят не только их финансово-экономические показатели, но и здоровье миллионов людей.  В частности, мы гордимся, что в число наших партнеров входят Инвитро, Гемотест, Клинико-диагностическая лаборатория (КДЛ).'
    ];
    return (
        <Stack
            sx={{
                width: '100%',
                justifyContent: 'center'
            }}>
            <Container
                sx={{
                    background: {
                        xs: ` url(${Triangles.src})   right top/177px no-repeat  `,
                        md: isMd
                            ? ` url(${Triangles.src})   right center/450px no-repeat  `
                            : ` url(${Triangles.src})   right center no-repeat  `
                    },

                    pt: [10, 15]
                }}>
                <Typography variant={isDesktop ? 'h1' : 'h2'} sx={{mb: [5, 6]}}>
                    Почему LDC?
                </Typography>
                <Grid container sx={{mb: [5.5, 12]}}>
                    <Grid item xs={12} md={6} xl={8}>
                        {ANSWER.map((item, index) => (
                            <Typography key={index} variant={'body1'} sx={{color: 'grey.description', mb: 1.25}}>
                                {item}
                            </Typography>
                        ))}
                    </Grid>
                </Grid>
            </Container>
        </Stack>
    );
};

export default WhyChooseUs;
