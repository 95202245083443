import * as React from 'react';
import {Box} from '@mui/material';
import Footer from 'components/Footer';
import Header from 'components/Header/Header';

const LandingLayout = ({children}) => {
    return (
        <Box display={'flex'} width={'100%'} height={'100%'} flexDirection={'column'}>
            <Header type={'landing'} />
            <Box display={'flex'} flexDirection={'column'} flexGrow={1}>
                {children}
            </Box>
            <Footer />
        </Box>
    );
};

export default LandingLayout;
